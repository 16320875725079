import { JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  HostBinding,
  inject,
  OnInit,
  signal,
  untracked
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthService } from '@pinnakl/auth/providers';
import { UserService } from '@pinnakl/core/data-providers';
import { PortalUserStore } from '@pinnakl/portal-v2/data-access';
import { PortalClient } from '@pinnakl/portal-v2/domain';
import {
  FirstLetterAvatarComponent,
  LocalSpinnerSimpleModeContainerComponent
} from '@pinnakl/shared/base-components';
import { PrimeSingleDropdownComponent, RouterToggleComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { MenuItem } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'investor-portal-header',
  standalone: true,
  templateUrl: './investor-portal-header.component.html',
  styleUrls: ['./investor-portal-header.component.scss'],
  imports: [
    RouterToggleComponent,
    InlineSVGModule,
    FirstLetterAvatarComponent,
    PrimeSingleDropdownComponent,
    ReactiveFormsModule,
    JsonPipe,
    OverlayPanelModule,
    RouterLink,
    LocalSpinnerSimpleModeContainerComponent,
    DividerModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorPortalHeaderComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly userService = inject(UserService);
  private readonly authService = inject(AuthService);
  private readonly portalUserStore = inject(PortalUserStore);
  readonly logoutProcessing = signal(false);
  readonly user = this.userService.getUser();
  readonly clients = computed(() => this.portalUserStore.portalUser()?.clients ?? []);
  readonly selectedClient = computed(() =>
    this.clients().find(client => client.publicId === this.portalUserStore.selectedClientPublicId())
  );
  readonly selectClientControl = new FormControl<PortalClient | undefined>(this.selectedClient(), {
    nonNullable: true
  });
  readonly tabs: MenuItem[] = [
    {
      label: 'Documents',
      routerLink: 'dataroom'
    }
  ];
  appVersion = localStorage.getItem('appVersion');

  @HostBinding('class') componentClasses = 'block w-full h-5rem';

  constructor() {
    effect(() => {
      const selectedClient = this.selectedClient();
      untracked(() => selectedClient && this.selectClientControl.setValue(selectedClient));
    });
  }

  ngOnInit(): void {
    this.selectClientControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        selectedClient =>
          selectedClient?.publicId &&
          this.portalUserStore.setSelectedClientPublicId(selectedClient.publicId)
      );
  }

  logout(): void {
    this.logoutProcessing.set(true);
    this.authService.logout();
  }
}
