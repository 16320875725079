import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppStateModule } from '@pinnakl/app-state';
import {
  AuthService,
  DEFAULTSCREEN,
  HttpConfigInterceptor,
  USERTYPE
} from '@pinnakl/auth/providers';
import { UserService } from '@pinnakl/core/data-providers';
import { EnvironmentModule } from '@pinnakl/core/environment';
import { CoreMaintenanceModule } from '@pinnakl/core/maintenance';
import { UploadListenerInterceptor } from '@pinnakl/core/web-services';
import { HTTP_SERVICE_URL } from '@pinnakl/shared/constants';
import { UserTypes } from '@pinnakl/shared/types';
import { UiToastMessageService } from '@pinnakl/shared/ui/prime';
import { getAppInstanceToken } from '@pinnakl/shared/util-helpers';
import { InlineSVGModule, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { AuthModule } from 'angular-auth-oidc-client';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const authConfig = {
  ...environment.authConfig,
  configId: getAppInstanceToken(
    environment.authConfig?.configId,
    location?.host,
    environment.appName
  )
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(appRoutes),
    importProvidersFrom(BrowserAnimationsModule),
    // todo: remove when new settings page used
    importProvidersFrom(AppStateModule),
    AuthService,
    importProvidersFrom(
      CoreMaintenanceModule.register({
        coreApiServerUrl: environment.httpServerUrl
      })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadListenerInterceptor,
      multi: true
    },
    {
      provide: HTTP_SERVICE_URL,
      useValue: environment.httpServerUrl
    },
    { provide: DEFAULTSCREEN, useValue: { prod: 'dataroom', dev: 'dataroom' } },
    { provide: USERTYPE, useValue: UserTypes.EXTERNAL },
    {
      provide: PinnaklUIToastMessage,
      useClass: UiToastMessageService
    },
    importProvidersFrom(EnvironmentModule.forRoot({ config: environment })),
    MessageService,
    UserService,
    importProvidersFrom(AuthModule.forRoot({ config: authConfig })),
    importProvidersFrom(InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }))
  ]
};
