import { EnvNames, PinnaklEnvironment } from '@pinnakl/core/environment';
import { AppNames } from '@pinnakl/shared/types';
import {
  authConfig,
  authUrls,
  fileServiceUrls,
  httpServerUrls,
  vdrFileAppHosts
} from './pinnakl-environment-options';

const envName: EnvNames = EnvNames.dev;

export const environment: PinnaklEnvironment = {
  envName: EnvNames[envName],
  fileServiceUrl: fileServiceUrls[envName],
  production: false,
  httpServerUrl: httpServerUrls[envName],
  appName: AppNames.INVESTOR_PORTAL,
  vdrFileAppHost: vdrFileAppHosts[envName],
  authConfig: authConfig(envName),
  authUrl: authUrls[envName]
};
