import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'pinnakl-access-denied',
  templateUrl: './access-denied.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessDeniedComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  title = '';

  ngOnInit(): void {
    this.title = this.route.snapshot.data['title'] ?? 'Requested page';
  }
}
