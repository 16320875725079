import { Route } from '@angular/router';
import { AuthenticatedGuardCanActivateChild } from '@pinnakl/auth/providers';
import { INVESTOR_PORTAL_ROUTES } from '@pinnakl/investor-portal/shell';
import { AccessDeniedComponent } from '@pinnakl/shared/pages';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivateChild: [AuthenticatedGuardCanActivateChild],
    children: INVESTOR_PORTAL_ROUTES
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  }
];
