import { Routes } from '@angular/router';
import { InvestorPortalAppContainerComponent } from '@pinnakl/investor-portal/feature';
import { VdrUsersFacadeService } from '@pinnakl/vdr/data-access';

export const INVESTOR_PORTAL_ROUTES: Routes = [
  {
    path: '',
    component: InvestorPortalAppContainerComponent,
    children: [
      {
        path: 'settings',
        providers: [VdrUsersFacadeService],
        loadChildren: () => import('@pinnakl/authentication').then(m => m.SettingsModule)
      },
      {
        path: 'fund-performance',
        loadChildren: () =>
          import('@pinnakl/crm/fund-performance/shell').then(m => m.CRM_FUND_PERFORMANCE_ROUTES)
      },
      {
        path: 'dataroom',
        loadChildren: () =>
          import('@pinnakl/vdr2/shell').then(({ VIRTUAL_DATA_ROOM_ROUTES }) =>
            VIRTUAL_DATA_ROOM_ROUTES(false)
          )
      },
      {
        path: '',
        redirectTo: 'dataroom',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'dataroom',
        pathMatch: 'full'
      }
    ]
  }
];
