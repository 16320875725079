<investor-portal-header></investor-portal-header>

<div class="investor-portal-content">
  @if (selectedClientPublicId()) {
    <router-outlet></router-outlet>
  } @else if (loadingPortalUser()) {
    <pinnakl-local-spinner-simple-mode-container
      containerHeight="100%"
      size="100px"
    ></pinnakl-local-spinner-simple-mode-container>
  } @else if (loadedPortalUser()) {
    <div class="w-full my-6 text-lg">No client to select</div>
  } @else {
    Something went wrong. Please contact Pinnakl support
  }
</div>
