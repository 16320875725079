import { EnvNames } from '@pinnakl/core/environment';
import { AllowedUserTypeAuthParam } from '@pinnakl/shared/types';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

export const fileServiceUrls = {
  local: 'https://localhost:53591/File/',
  dev: 'https://external-services-dev.pinnakl.com/File/',
  staging: 'https://documents.pinnakl.com/File/',
  prod: 'https://documents.pinnakl.com/File/'
};

export const httpServerUrls = {
  local: 'https://localhost:51490',
  dev: 'https://apicore-dev.pinnakl.com',
  staging: 'https://apicore-prod-002.pinnakl.com',
  prod: 'https://apicore-prod-002.pinnakl.com'
};

export const authUrls = {
  local: 'https://localhost:5149',
  dev: 'https://apicore-dev.pinnakl.com',
  prod: 'https://apicore-prod-001.pinnakl.com'
};

export const authConfig = (envName: EnvNames): OpenIdConfiguration => ({
  authority: authUrls[envName],
  postLogoutRedirectUri: window.location.origin,
  redirectUrl: window.location.origin,
  clientId: 'angularclient',
  configId: 'pinnakl_' + envName,
  scope: 'openid profile email api_core offline_access',
  responseType: 'code',
  silentRenew: true,
  useRefreshToken: true,
  ignoreNonceAfterRefresh: true,
  logLevel: envName === EnvNames.dev ? LogLevel.Debug : LogLevel.Error,
  historyCleanupOff: false,
  autoCleanStateAfterAuthentication: true,
  triggerAuthorizationResultEvent: false,
  startCheckSession: false,
  disableRefreshIdTokenAuthTimeValidation: true,
  renewTimeBeforeTokenExpiresInSeconds: 60 * 15,
  tokenRefreshInSeconds: 60 * 10,
  autoUserInfo: false,
  unauthorizedRoute: '/login' + window.location.search,
  customParamsAuthRequest: { aut: AllowedUserTypeAuthParam.EXTERNAL },
  customParamsRefreshTokenRequest: { aut: AllowedUserTypeAuthParam.EXTERNAL },
  customParamsEndSessionRequest: { aut: AllowedUserTypeAuthParam.EXTERNAL }
});

export const vdrFileAppHosts = {
  [EnvNames.local]: 'https://ir.dev.pinnakl.com',
  [EnvNames.dev]: 'https://ir.dev.pinnakl.com',
  [EnvNames.prod]: 'https://ir.pinnakl.com'
};
