<p-tabMenu
  [model]="config"
  [ngClass]="styleClass"
  [scrollable]="scrollable"
  class="mt-2"
  (activeItemChange)="activeItemChangeEvent.emit($event)"
>
  @if (itemTemplate) {
    <ng-template
      let-item
      pTemplate="item"
    >
      <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
    </ng-template>
  }
</p-tabMenu>
