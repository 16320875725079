import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PortalUserStore } from '@pinnakl/portal-v2/data-access';
import { LocalSpinnerSimpleModeContainerComponent } from '@pinnakl/shared/base-components';
import { InvestorPortalHeaderComponent } from '../investor-portal-header/investor-portal-header.component';

@Component({
  selector: 'investor-portal-app-container',
  standalone: true,
  imports: [RouterOutlet, InvestorPortalHeaderComponent, LocalSpinnerSimpleModeContainerComponent],
  templateUrl: './investor-portal-app-container.component.html',
  styleUrl: './investor-portal-app-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorPortalAppContainerComponent implements OnInit {
  private readonly portalUserStore = inject(PortalUserStore);

  selectedClientPublicId = this.portalUserStore.selectedClientPublicId;
  loadingPortalUser = this.portalUserStore.portalUserLoading;
  loadedPortalUser = this.portalUserStore.portalUserLoaded;

  ngOnInit(): void {
    this.portalUserStore.loadPortalUser();
  }
}
