import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  standalone: true,
  selector: 'prime-router-toggle',
  templateUrl: './router-toggle.component.html',
  styleUrls: ['./router-toggle.component.scss'],
  imports: [TabMenuModule, NgClass, NgIf, NgTemplateOutlet]
})
export class RouterToggleComponent {
  @Input({ required: true }) config: MenuItem[] = [];
  @Input() scrollable = true;
  @Input() styleClass = 'pinnakl-router-toggle';
  @Output() readonly activeItemChangeEvent = new EventEmitter<MenuItem>();
  @ContentChild('itemTemplate', { static: false }) itemTemplate: TemplateRef<any> | null = null;
}
