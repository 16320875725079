<div class="investor-portal-header-wrapper w-full h-full border-bottom-1 border-bluegray-200">
  <div class="h-full flex align-items-center">
    <img
      class="h-1_25rem mx-4"
      src="/assets/images/big-pinnakl-logo.png"
    />
    <div class="h-full px-4 border-left-1 border-bluegray-200 flex align-items-center">
      <prime-single-dropdown
        [formControl]="selectClientControl"
        [options]="clients()"
        dataKey="publicId"
        optionLabel="clientName"
        styleClass="w-20rem"
      ></prime-single-dropdown>
    </div>
  </div>
  <div class="flex align-items-center">
    <prime-router-toggle [config]="tabs"></prime-router-toggle>
  </div>
  <div class="flex align-items-center justify-content-end pr-4">
    <div
      #userPopup
      (click)="op.toggle($event)"
      class="relative cursor-pointer border-round-xl px-3 py-2 border-1 border-bluegray-200 flex align-items-center gap-2"
    >
      <i
        class="flex w-1rem h-1rem"
        inlineSVG="sprites.svg#three-hor-lines"
      ></i>
      <pinnakl-first-letter-avatar
        [nameString]="user?.firstName ?? user?.lastName ?? user?.email ?? ''"
        backgroundColor="#2A98FF"
        color="white"
        height="24px"
        width="24px"
      ></pinnakl-first-letter-avatar>
    </div>
    <p-overlayPanel
      #op
      [appendTo]="userPopup"
      styleClass="p-overlaypanel-center p-overlay-arrow-right w-7rem"
    >
      <div
        [title]="user?.firstName ?? user?.lastName ?? user?.email ?? ''"
        class="w-full my-2 px-3 text-center border-round text-color-secondary text-ellipsis-line-1"
      >
        {{ user?.firstName ?? user?.lastName ?? user?.email ?? '' }}
      </div>
      <p-divider styleClass="m-0" />
      <div class="flex flex-column align-items-center w-full p-2 gap-2">
        <a
          [routerLink]="['/settings']"
          class="w-full text-center cursor-pointer flex-grow-1 text-color border-round p-2 no-underline hover:bg-bluegray-200"
          >Settings</a
        >
        <div
          (click)="logout()"
          class="w-full text-center cursor-pointer border-round p-2 hover:bg-bluegray-200 flex-grow-1"
        >
          Logout
        </div>
      </div>
      @if (appVersion) {
        <p-divider styleClass="m-0" />
        <div class="w-full my-2 text-center border-round text-color-secondary">
          {{ appVersion }}
        </div>
      }
    </p-overlayPanel>
  </div>
</div>

@if (logoutProcessing()) {
  <pinnakl-local-spinner-simple-mode-container
    [containerNgStyle]="{
      top: '0',
      left: '0'
    }"
    backgroundColor="rgba(255, 255, 255, 0.5)"
    containerHeight="100vh"
    containerWidth="100vw"
    position="absolute"
    size="150px"
  ></pinnakl-local-spinner-simple-mode-container>
}
