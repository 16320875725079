import { Component, effect, inject, Injector, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@pinnakl/auth/providers';
import { AuthPopupService } from '@pinnakl/authentication';
import { PortalUserStore } from '@pinnakl/portal-v2/data-access';
import { PortalUser } from '@pinnakl/portal-v2/domain';
import {
  LocalSpinnerSimpleModeContainerComponent,
  PinnaklGlobalSpinnerComponent
} from '@pinnakl/shared/base-components';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { VdrUser } from '@pinnakl/vdr/domain';
import { ToastModule } from 'primeng/toast';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';

/*
 * After removing old settings component we need to remove shared-ui from project.json
 * */

@Component({
  standalone: true,
  imports: [
    RouterModule,
    LocalSpinnerSimpleModeContainerComponent,
    ToastModule,
    PinnaklGlobalSpinnerComponent
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AuthPopupService, PinnaklSpinnerService]
})
export class AppComponent implements OnInit {
  private readonly injector = inject(Injector);
  private readonly toast = inject(PinnaklUIToastMessage);
  private readonly authService = inject(AuthService);
  private readonly portalUserStore = inject(PortalUserStore);
  private readonly authPopupService = inject(AuthPopupService);
  readonly isLoading = signal(true);

  constructor() {
    this.authService.init();
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$
      .pipe(
        distinctUntilChanged(),
        filter(v => v),
        take(1)
      )
      .subscribe(() => {
        this.isLoading.set(false);
        this.observePortalUser();
      });
  }

  private observePortalUser(): void {
    effect(
      () => {
        const portalUser = this.portalUserStore.portalUser();
        if (portalUser?.passwordResetRequired) {
          this.openInitialPasswordSetupModal(portalUser);
        }
      },
      { injector: this.injector }
    );
  }

  private openInitialPasswordSetupModal(portalUser: PortalUser): void {
    this.authPopupService
      .openPortalRegistrationModal(portalUser as VdrUser)
      .afterClosed()
      .pipe(
        switchMap(updatedPortalUser =>
          this.portalUserStore.updatePortalUser({
            ...updatedPortalUser,
            id: portalUser.id,
            authType: portalUser.authType,
            passwordResetRequired: false
          })
        ),
        tap(() => this.toast.success('Your password has been successfully updated.'))
      )
      .subscribe(() => {
        this.authService.passwordResetDone();
      });
  }
}
