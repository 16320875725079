@if (isLoading()) {
  <pinnakl-local-spinner-simple-mode-container
    containerHeight="100vh"
    containerWidth="100vw"
    size="150px"
  ></pinnakl-local-spinner-simple-mode-container>
} @else {
  <router-outlet></router-outlet>
}
<p-toast></p-toast>

<!-- TODO: delete asap -->
<pinnakl-spinner></pinnakl-spinner>
